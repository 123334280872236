<template>
  <div>
    <users-dialog v-model="showUserDialog" :id="idEdit" @setUser="setUser" :api="'/manager/users'" />
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title
        >{{ data.name ? data.name : "Новый инвестор" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <a-form-model
        v-model="data"
        :model="[model[0]]"
        :errors="errors"
        :config="{
          class2: 'layout wrap row px-2 pb-3',
          dense: true,
        }"
        @validate="validate($event)"
      />

      <div class="layout wrap row px-2">
        <a-form-model-flex v-model="data" :model="[model[2]]" :errors="errors" @validate="validate($event)" class="flex sm6 px-1" />
        <a-form-model-flex v-model="data" :model="[model[3]]" :errors="errors" @validate="validate($event)" class="flex sm6 px-1" />
        <a-form-model-flex v-model="data" :model="[model[4]]" :errors="errors" @validate="validate($event)" class="flex sm6 px-1" />
        <a-form-model-flex v-model="data" :model="[model[5]]" :errors="errors" @validate="validate($event)" class="flex sm6 px-1" />
      </div>
      <v-row>
        <v-col cols="12">
          <a-form-model
            v-model="data"
            :model="[model[6], model[7], model[8]]"
            :errors="errors"
            @validate="validate($event)"
            :config="{
              dense: true,
            }"
          />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn v-if="$root.profile.role == 1000" color="lime accent-2" @click="showUserDialogFun(data.user_id)"
          ><btn-title icon="far fa-user">Пользователь</btn-title></v-btn
        >
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id && getAccess('investor.delete')" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess } from "../../components/mixings";

export default {
  components: {
    usersDialog: () => import("./usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess],
  props: {
    value: Boolean,
    id: Number,
    object_id: Number,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: this.$store.getters["config/get"].models.investors.form,
      api: "/mechti/investors",
      removeDialogShow: false,
      showUserDialog: false,
      idEdit: 0,
      name_: null,
    };
  },
  computed: {},
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    afterFetchData() {
      if (this.data.name) {
        this.name_ = JSON.parse(JSON.stringify(this.data.name));
      } else this.name_ = null;
    },

    async submit() {
      if (await this.validateAll(this.data)) {
        if (this.name_ !== this.data.name) {
          const resp = await this.$axios.get(this.api, {
            params: {
              filters: { name: this.data.name },
            },
          });
          let status = resp.data.status == "ok";
          if (!status) {
            this.$root.$emit("show-error", {
              text: "Ошибка....",
            });
            return;
          }
          if (resp.data.data.length) {
            this.errors.name = "Имя занято";
            return;
          }
        }
        await this.post(this.data, this.api);
        return true;
      }
      return false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$router.push({ name: "investors" });
      this.$store.dispatch("dirs/fitchDirs", "investor");
    },
    afterSave(data, status) {
      this.$store.dispatch("dirs/fitchDirs", "investor");
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
    showUserDialogFun() {
      this.idEdit = this.data.user_id || 0;
      this.showUserDialog = true;
      console.log(this.idEdit, this.showUserDialog);
    },
    async setUser(e) {
      console.log("setuser", e);
      if (e.id == this.data.user_id) return;
      let status = false;
      this.loading = true;

      let data = { id: this.data.id, user_id: e.id };
      if (this.id) {
        let response = await this.$axios.post(this.api, data);
        status = response.data.status == "ok";
      } else status = true;
      this.loading = false;
      if (status) {
        this.data.user_id = e.id;
        this.data.user_name = e.login;
        this.$root.$emit("show-info", {
          text: "Пользователь привязан",
        });
      } else {
        this.$root.$emit("show-error", {
          text: "Ошибка привязки пользователя",
        });
      }
    },
  },
};
</script>
